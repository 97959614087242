import React from 'react'

import "../index.css";
import Home from './home';
import PageNotFound from './pageNotFound';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation
  
  
  } from 'react-router-dom';
import Dashboard from './dashboard';
// import ReduxExample from './reduxExample';
// import Signup from './signup';
import Modeldetails from './modeldetails';

function Login() {
      return (
        <Router>      
           <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/dashboard' element={<Dashboard />}/>
              <Route path='/modeldetails' element={<Modeldetails />}/>
              {/* <Route path='/reduxExample' element={<ReduxExample />}/> */}
              {/* <Route path='/signup' element={<Signup />}/> */}
              <Route path='*' element={<PageNotFound />}/>
              
            </Routes>    
        </Router>     
      );
  
   
  }

export default Login
