import { useLocation,useNavigate, } from "react-router-dom";
// import { PieChart } from '@mui/x-charts/PieChart';
// import { BarChart } from '@mui/x-charts/BarChart';
import { useState } from "react";
import ResponsiveAppBar from './appBar'
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartTooltip,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";

import { Button, Checkbox, Slider, TextField, input } from '@mui/material'
const Modeldetails=()=>{
    //const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
  const labelContent = (props) => {
    debugger;
    let formatedNumber = Number(props.dataItem.value/100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return ` ${formatedNumber}`;
  };
  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: 'right' }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    )
  }
    debugger;
    const [modelData,setModelData]=useState(JSON.parse(localStorage.getItem("modelData")));
    
    
    const navigate = useNavigate();
    const [gridData, setgridData] = useState(modelData.ocInvModelDetails);

   var piedata=[];
    for(var i=0;i<modelData.ocInvModelDetails.length;i++){
        piedata.push({
            id:i,
            value:modelData.ocInvModelDetails[i].prcnt,
            label:modelData.ocInvModelDetails[i].assetNm
          });
        
    }
    var bardataX=[];
    var bardataY=[];

    for(var i=0;i<modelData.ocInvModelDetails.length;i++){
        bardataX.push(modelData.ocInvModelDetails[i].assetNm);
        bardataY.push(modelData.ocInvModelDetails[i].mv);
       
    }
    const handleBack=()=>
        {
            debugger;
            

            navigate("/dashboard");
         //  navigate("/");
        }

      const columns = [
        {
          title: 'Asset Name',
          dataIndex: 'assetNm',
          key: 'assetNm',
          render: (text) => <a>{text}</a>,
          colSpan: 1,
          width: '30%',
        },
        {
          title: 'Remarks',
          dataIndex: 'remarks',
          key: 'remarks',
          colSpan: 1,
          width: '30%',
        },
        {
            title: 'Ratio',
            dataIndex: 'prcnt',
            key: 'prcnt',
            render: (text) => <a>{text}</a>,
            colSpan: 1,
            width: '12%',
          },
          {
            title: 'Market Value',
            dataIndex: 'mv',
            key: 'mv',
            render: (text) => <a>{Number(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</a>,
            colSpan: 1,
            width: '12%',
          },
       
        // {
        //   title: 'Tags',
        //   key: 'tags',
        //   dataIndex: 'tags',
        //   render: (_, { tags }) => (
        //     <>
        //       {tags.map((tag) => {
        //         let color = tag.length > 5 ? 'geekblue' : 'green';
        //         if (tag === 'loser') {
        //           color = 'volcano';
        //         }
        //         return (
        //           <Tag color={color} key={tag}>
        //             {tag.toUpperCase()}
        //           </Tag>
        //         );
        //       })}
        //     </>
        //   ),
        // },
       
      ];
      console.log("PIEDATA",piedata);
      console.log("BARDATA",bardataY);
    return(
<>



<ResponsiveAppBar></ResponsiveAppBar>

<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
  {/* PieChart */}
  <div style={{ flex: 1, paddingRight: 10 }}>
  <Chart>
    <ChartTitle  />
    <ChartLegend position="bottom" />
    {/* <ChartTooltip render={defaultTooltipRender} /> */}
    {/* <ChartTooltip format='n2' /> */}
    <ChartSeries>
      <ChartSeriesItem
        type="donut"
        data={piedata}
        field="value"
        categoryField="label"
        labels={{
          visible: true,
          position: "outsideEnd",
          content: labelContent,
        }}
      />
    </ChartSeries>
  </Chart>
    {/* <PieChart
      width={600}
      height={400}
      margin={{ top: 100, bottom: 100, left: 50, right: 50 }}
      
      slotProps={{ legend: { hidden: true } }}
      
      series={[
        {
          data: piedata,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        }
      ]}
      
    /> */}
  </div>

  {/* Vertical Divider */}
  <div style={{ borderLeft: '1px solid #ccc', height: 400, margin: 'auto 20px' }}></div>

  {/* BarChart */}
  <div style={{ flex: 1, paddingLeft: 10 }}>
  <Chart 
              
              style={{ height: "400px" }}>
              <ChartLegend position="bottom" />

              <ChartValueAxis>
                <ChartValueAxisItem
                  // title={{
                  //     text: "Percentage",
                  // }}
                  min={0}
                  labels={{
                    visible: true,

                    // rotation: 85,
                    //format: "d",
                    //content: FormatLongNumber

                  }}
                />
              </ChartValueAxis>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    rotation: 30,
                    format: "d",
                  }}
                //  categories={categoryAxis} 
                />
              </ChartCategoryAxis>
              <ChartTooltip format='n2' />
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  data={modelData.ocInvModelDetails}
                  field="mv"
                  categoryField="assetNm"

                  labels={{
                    visible: false,
                    //content: labelContent1,
                  }}
                  overlay={{
                    gradient: "sharpBevel",
                  }}
                />
              </ChartSeries>
            </Chart>
    {/* <BarChart
      width={900}
      height={400}
      margin={{ top: 100, bottom: 100, left: 70, right: 50 }}
     
      slotProps={{ legend: { hidden: true } }}
      series={[
        { data: bardataY }
      ]}
      bottomAxis={{    tickLabelStyle: {
        angle: 5,
        textAnchor: 'start',
        fontSize: 14,
        textWrap:'wrap'
      
      },
      
      tickLabelInterval:true
      }}
      xAxis={[{ data: bardataX, scaleType: 'band' , categoryGapRatio: 0.3,
        barGapRatio: 0.1
      }]}
    /> */}
  </div>
</div>

<div style={{ height: 350, width: '100%', marginTop: 20 }}>
  {/* <Table columns={columns} dataSource={gridData} bordered /> */}
  <Grid style={{ height: "400px" }} 
                     
                     // data={orderBy(mnrAssetData, sort)}
                     data={gridData}
                     // filterable={true}
                      // resizable={true}
                      // reorderable={true}
                      // sortable={true}
                      //   skip={0}
                      //   take={100}
                        
                      // sort={sort}
                      // onSortChange={(e) => {
                      //   setSort(e.sort);
                      // }}
                      // pageable={{
                      //   pageSizes: true,
                      //    buttonCount: 4
                      // }}
                    //  pageable={true}
                      
                     // pageSize={100}
                    //  total={resultState.total}
                      
                      // onDataStateChange={onDataStateChange}
                      // {...dataState}
                     

                    >
                   
                      <Column field="assetNm" title="Asset Name" width="auto"   />

                      <Column field="remarks" title="Remarks" width="auto" 

                      />
                      <Column field="prcnt" title="Ratio" cell={NumberCell} 

                        width="auto" format="{0:n2}" filter="numeric" headerClassName='rightHeader'
                         />
                          <Column field="mv" title="Market Value" cell={NumberCell} 

                        width="auto" format="{0:n2}" filter="numeric" headerClassName='rightHeader'
                         />
                      {/* <Column field="mvPercent" title="Market Value(%)" headerClassName='rightHeader' cell={NumberCell}
                
                  format="{0:n2}" width="auto" filter={"numeric"} columnMenu={ColumnMenu} /> */}

                    </Grid>
 
</div>

<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
  <Button style={{ marginRight: 10 }} variant="contained" size='large' onClick={handleBack}>Back</Button>
  <Button variant="contained" size='large'>Invest</Button>
</div>
</>
    );
}
export default Modeldetails