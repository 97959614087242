import React from 'react'
import WW_text_logo from '../Images/WWLogo.jpg';
const LogoPage = () => {
  const styleLogo = {
    width: '120px'
  };
  return (
    <div>
       <img style={styleLogo} src={WW_text_logo} alt="Logo" />
    </div>
  )
}

export default LogoPage
