import logo from './logo.svg';
import './App.css';
import Login from './components/login';
// import { Provider } from 'react-redux';

// import store from './app/store'
import InitialLoading from './components/initialLoading';
function App() {
  
  return (
    <>
    {/* <InitialLoading></InitialLoading> */}
    {/* <Provider store={store}> */}
    <Login />
    {/* </Provider> */}
  </>
  );
}

export default App;
