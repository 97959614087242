import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation
} from 'react-router-dom';
import ResponsiveAppBar from './appBar'
import UserForm from './userForm';
const Dashboard = () => {
  debugger;
  var data=JSON.parse(localStorage.getItem("postData")) ;
  return (
    <div>
      <ResponsiveAppBar></ResponsiveAppBar>
    <UserForm information={data.userDetails}></UserForm>
     

    </div>
  )
}

export default Dashboard
