import { Button, Checkbox, Slider, TextField, input } from '@mui/material'

import styled from "styled-components";
import React, { useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'react-bootstrap'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRange } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
import Logging from './loginloader';

dayjs.extend(customParseFormat);
const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
     & label.Mui-disabled {
    color: #D0D0D0;
  }
   & .MuiOutlinedInput-root {
    &.Mui-disabled fieldset {
      border-color: #D0D0D0;
    }
  }
    
     & .MuiOutlinedInput-root {
    color:white
  }
   
     & label {
    color: white;
  }
     & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
  }
     & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #51BAFD;
    }
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;
const UserForm = ({ information }) => {
    debugger;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    information.dob = new Date(information.dob);
    var today = new Date();

    const navigate = useNavigate();
    information.dob = information.dob.getFullYear() + "-" + (information.dob.getMonth() + 1).toString().padStart(2, '0') + "-" + information.dob.getDate().toString().padStart(2, '0');
    information.addrCountry = "India";
    const [info, setinfo] = useState(information);

    const marks = [
        {
            value: 1,
            label: 'Low',
        },
        {
            value: 2,
            label: 'Medium',
        },
        {
            value: 3,
            label: 'High',
        }
    ];
    const onChangeDate = (e) => {
        debugger;
        setinfo({ ...info, dob: e.$d })
       // console.log(date, dateString);
    };

    const handleSubmit = async (e) => {
        debugger;
        setLoading(true);
        e.preventDefault();
        const postdata = info;
        var responseData = "";
        var infoData = "";
        var token=localStorage.getItem('token');

        const config = {
            headers: {
                'authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        debugger;
        await axios.post('/Person',
            postdata,
            config

        )
            .then((response) => {
                debugger;
                setLoading(false);
                //  setinfo({ ...info})
                responseData = response.data;

                localStorage.setItem('modelData', JSON.stringify(response.data));
                let userDetails = info;

                const postData = { userDetails };
                localStorage.setItem('postData', JSON.stringify(postData));
                navigate("/modeldetails");

            })
            .catch((error) => {
                debugger;
                setLoading(false);
                setErrorMessage(error.response.data)
                // navigate("/dashboard");

            })

    }
    const dateFormat = 'YYYY-MM-DD';
    if (loading) {
        return <Logging></Logging>
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Container fluid className="p-5">

                    <Row className="justify-content-center">
                        <div className="col-md-10">

                            <Card className="card-registration" style={{ borderRadius: '15px', backgroundColor: '#f8f9fa' }}>
                                <CardBody className="p-4">

                                    <h3 className="fw-normal mb-4">General Information</h3>
                                    <Row>
                                        <div className="col-md-4">
                                            <TextField label="First Name" variant="outlined" required size="small" fullWidth
                                                value={info.frstNm}
                                                onChange={(e) => setinfo({ ...info, frstNm: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="Middle Name" variant="outlined" size="small" fullWidth
                                                value={info.mdlNm}
                                                onChange={(e) => setinfo({ ...info, mdlNm: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="Last Name" variant="outlined" required size="small" fullWidth
                                                value={info.lstNm}
                                                onChange={(e) => setinfo({ ...info, lstNm: e.target.value })} />
                                        </div>
                                    </Row>

                                    <Row className="mt-4">
                                        <div className="col-md-4">
                                            <TextField label="Email" variant="outlined" required size="small" fullWidth
                                                value={info.emailAdrs}
                                                disabled />
                                        </div>
                                        <div className="col-md-4">  {/* Spacing below the label */} 
                                            
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            
                                            <DatePicker 
                                                minDate={dayjs(today, dateFormat)} label="Date Of Birth" size="s" fullWidth value={info.dob ? dayjs(info.dob, dateFormat) : ""} onChange={(newValue)=>setinfo({ ...info, dob:newValue })} required />
                                                </LocalizationProvider>
                                                </div> 
                                        <div className="col-md-4">
                                            <TextField type="number" label="Total Investable Amount" variant="outlined" required size="small" fullWidth
                                                value={info.invAmount}
                                                onChange={(e) => setinfo({ ...info, invAmount: e.target.value })}
                                                InputProps={{ inputProps: { min: 2000 } }} />
                                        </div>
                                    </Row>

                                    <Row className="mt-4">
                                        <div className="col-md-8">
                                            <label>Risk Level:</label>
                                            <Slider defaultValue={1} aria-label="Risk Level" fullWidth
                                                value={info.riskScore === "L" ? 1 : info.riskScore === "M" ? 2 : 3}
                                                onChange={(e) => setinfo({ ...info, riskScore: e.target.value === 1 ? "L" : e.target.value === 2 ? "M" : "H" })}
                                                valueLabelDisplay="auto"
                                                step={1} marks={marks} min={1} max={3} />
                                        </div>
                                    </Row>
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <hr></hr>

                                        </div>
                                    </div>

                                    <h3 className="fw-normal mt-2 mb-4">Contact Details</h3>
                                    <Row>
                                        <div className="col-md-4">
                                            <TextField label="Address" variant="outlined" required size="small" fullWidth
                                                value={info.addrLine1}
                                                onChange={(e) => setinfo({ ...info, addrLine1: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="City" variant="outlined" required size="small" fullWidth
                                                value={info.addrCity}
                                                onChange={(e) => setinfo({ ...info, addrCity: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="State" variant="outlined" required size="small" fullWidth
                                                value={info.addrState}
                                                onChange={(e) => setinfo({ ...info, addrState: e.target.value })} />
                                        </div>
                                    </Row>

                                    <Row className="mt-4">
                                        <div className="col-md-6">
                                            <TextField label="Country" variant="outlined" required size="small" fullWidth
                                                value="India" disabled />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField label="Zip Code" variant="outlined" required size="small" fullWidth
                                                value={info.addrZip}
                                                onChange={(e) => setinfo({ ...info, addrZip: e.target.value })} />
                                        </div>
                                    </Row>

                                    <Row className="mt-4">
                                        <div className="col-md-4">
                                            <TextField label="Home Phone" variant="outlined" size="small" fullWidth
                                                value={info.homeNumb}
                                                onChange={(e) => setinfo({ ...info, homeNumb: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="Office Phone" variant="outlined" size="small" fullWidth
                                                value={info.officeNumb}
                                                onChange={(e) => setinfo({ ...info, officeNumb: e.target.value })} />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField label="Cell Phone" variant="outlined" required size="small" fullWidth
                                                value={info.cellNumb}
                                                onChange={(e) => setinfo({ ...info, cellNumb: e.target.value })} />
                                        </div>
                                    </Row>

                                    <Row className="mt-4">
                                        <div className="col-md-12 text-center">
                                            <Button type="submit" variant="contained" size="lg">Next</Button>
                                        </div>
                                    </Row>

                                </CardBody>
                            </Card>

                        </div>
                    </Row>

                </Container>
                <div className='error text-center mt-2'>{errorMessage}</div>
            </form>    </div>


    )
}

export default UserForm
