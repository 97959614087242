import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoPage from './logoPage';
import 'font-awesome/css/font-awesome.min.css'
import { AccountCircle } from '@mui/icons-material';
// import { logOut, selectUser } from '../features/auth/authSlice';
// import { useDispatch, useSelector } from 'react-redux';
const pages = ['Investment banker library', 'Payment gateway', 'Self made advisor','Virtual Guide'];
const settings = [ 
    // { text: 'Profile', icon: 'fa-user' }, 
    // { text: 'Account', icon: 'fa-cog' }, 
    { text: 'Dashboard', icon: 'fa-bar-chart' }, 
    { text: 'Logout', icon: 'fa-sign-out' }, ];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
//   const dispatch=useDispatch();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
//   const user=useSelector(selectUser);
  const handleCloseUserMenu = (e) => {
    
    
 // console.log(user);
    if(e.target.innerText=='Logout')
        {
            navigate("/");
           // dispatch(logOut());
            localStorage.clear();

        }
        else if(e.target.innerText=='Account')
            {
            navigate("/reduxExample");
        }
       
        else if(e.target.innerText=='Dashboard')
            {
            navigate("/dashboard");
        }

    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{backgroundColor:'#f4f4f4'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{minHeight: '48px', padding: '0 10px'}}>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            //href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LogoPage></LogoPage>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color: '#006fba'}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
              <LogoPage sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start' }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block', '&:hover':{color: '#006fba', backgroundColor: '#0000000A' } ,textTransform: 'uppercase'}}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>  
            <Tooltip title="Open settings"> 
            <IconButton onClick={handleOpenUserMenu}   size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"> 
            {/* <i className="fa fa-user-circle" style={{ fontSize: '1.5rem', color: '#006fba' }}></i> */}
            <AccountCircle  style={{ fontSize: '1.5rem', color: '#006fba' }} />
            
             </IconButton> 
             </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
               <MenuItem key={setting.text} onClick={handleCloseUserMenu}> 
               <i className={`fa ${setting.icon}`} style={{ marginRight: '10px' }}></i>
                <Typography textAlign="center">{setting.text}</Typography> 
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
