import React from 'react'
import { useState, useEffect } from 'react'
import "../index.css";
// import { login } from '../features/auth/authSlice'
import WW_text_logo from '../Images/WWLogo.jpg'
import { FaKey, FaLock, FaMicrosoft, FaUserAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import Dashboard from './dashboard';
import Box from '@mui/material/Box';
import firstImg from '../Images/1.png';
import secondImg from '../Images/2.png';
import thirdImg from '../Images/3.png';
import axios from 'axios';
import LogoPage from './logoPage';
import TextField from '@mui/material/TextField';

import { PublicClientApplication } from "@azure/msal-browser";
import Logging from './loginloader';
import { useMsal } from "@azure/msal-react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation
} from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import "../index.css";
import { IconButton } from '@mui/material';
// import { useDispatch } from 'react-redux';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
debugger;

if (process.env.NODE_ENV != 'development') {

  axios.defaults.baseURL = process.env.REACT_APP_PROD_URL;
}
else {
  axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
}
let clientId = process.env.REACT_APP_CLIENT_ID;
let tenentId = process.env.REACT_APP_TENANT_ID;
let clientSecret = process.env.REACT_APP_CLIENT_SECRET;
const Home = () => {

  const styleLogo = {
    width: '200px'

  }
  const [MMPloading, setMMPLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMMPLoading(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  //const LockedMsg = location.state;
  const [email, setEmail] = useState('');
  const [pswrdShow, setPswrdShow] = useState(false);
  // User Login info
//   const dispath = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attempt, setattempt] = useState(0);
  const [isLocked, setisLocked] = useState(0);
  const [isMailFailed, setisMailFailed] = useState(0);
  //const [attempt,setattempt]=useState(localStorage.getItem("attempt")?JSON.parse(localStorage.getItem("attempt")):0);
  const [disable, setdisable] = useState(attempt === 5 ? true : false);
  const [checked, setChecked] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  }; 
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    // setOpen(true);
    // setScroll(scrollType);
    navigate("/signup");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // useEffect(() => {
  //   debugger;

  //   let LockedMsg2 = location.state?.lockedmsg;
  //   if (location.state?.islocked) {
  //     setisLocked(1);
  //   }

  //   let isAuth = JSON.parse(localStorage.getItem('token'));
  //   if (isAuth && isAuth !== null) {
  //     if (isAuth.token && isAuth.token !== null) {
  //       debugger;
  //       localStorage.removeItem('token');

  //       navigate("/");

  //     }

  //   }
  // }, [])



//   


  const GoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      debugger;
      setLoading(true);
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${codeResponse.access_token}` },
        })
        .then(res => res.data);

      console.log(userInfo);
      debugger;
      let Username = userInfo.email;
      let Password = codeResponse.access_token + '++GO'; //process.env.REACT_APP_PASS;
      localStorage.setItem('tokenGoogle', JSON.stringify(codeResponse.access_token));
      const data = { Username, Password };


      //let userDetails = data;

      // const postData = { userDetails };
      // navigate("/dashboard", { state: postData });
      axios.post('/auth/login', data)
        .then((response) => {

          // console.log(response);
          debugger;
          if (response.statusText === 'OK' || response.statusText === '') {

            let userDetails = response.data;

            const postData = { userDetails };
            localStorage.setItem('token', userDetails.token);
            // localStorage.setItem('email', JSON.stringify(Username));
            // localStorage.setItem('userId', JSON.stringify(token.userId));
            // handlesettoken(token);
            setLoading(false);
            localStorage.setItem('postData', JSON.stringify(postData));
            navigate("/dashboard");

            // navigate("/dashboard",{state:1});
          }

        })
        .catch((error) => {
          debugger;
          setErrorMessage(error.response.data)
          setLoading(false);
          console.log("my error is " + error);
        })



    }

    //console.log(codeResponse)},
    // onError: (error) => console.log('Login Failed:', error)



  });

//   const handlesettoken = (token) => {
//     debugger;
//     var time = new Date();
//     dispath(login({
//       token: token,
//       logIntime: time,
//       password: "abc",

//     }));
//   }
  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const styleLogin = {
    float: 'right'

  };
  const togglePswrdShow = (event) => {
    debugger;
    event.preventDefault();
    setPswrdShow(!pswrdShow);
    return false;
  }
  const handlCheckChange = (e) => {

    setChecked(!checked);
    setButtonEnable(!checked);
  }
   return (
    <div className='login-page container'>
      <div className="row vh-50 align-items-center">
        {/* Left Section - Text Content */}
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-start">
          
          <p className="lead">
            Wealth World is a unique platform providing 360-degree advisory services. 
            Our goal is to optimize and maximize your return on investment with ease and minimal risk.
          </p>
          <p>
            Explore investments in sectors such as mutual funds, stocks, fixed deposits, property, and more. 
            Let Wealthworld guide you to make informed decisions and secure your financial future.
          </p>
        </div>

        {/* Right Section - Login Form */}
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
          <div className="login-form text-center">

            <img src={WW_text_logo} alt="Wealthworld Logo" style={{ width: '200px' }} />
            <br></br>
            <button className='btn btn-primary mt-3' style={{ width: '250px' }} onClick={GoogleLogin}>
              Sign in using Google
            </button>

            <div className='error text-center mt-2'>{errorMessage}</div>
            {loading && <Logging />}

            <div className='text-center pt-1 text-primary p-1 m-1'>
              <FaLock />
            </div>
            <div className='text-muted mt-1'>Wealth World, 2024</div>
          </div>
        </div>
      </div>

      <div className='row'>

      <div className="landing-content container">
  {/* Section 1 */}
  <div className="row mb-5">
    <div className="col-md-6 d-flex flex-column justify-content-center">
      <h2 className="landing-section-title">Why Choose Wealth World?</h2>
      <p className="landing-paragraph">
        Our platform is backed by a team of experts with deep experience in banking and technology. We leverage cutting-edge data analysis and Artificial Intelligence to guide you through every investment avenue, including:
      </p>
      <ul className="landing-list">
        <li>Mutual Funds, Stocks, and Fixed Deposits</li>
        <li>Real Estate and Property Investments</li>
        <li>Gold, Cryptocurrency, and the Bullion Market</li>
        <li>Start-up Investments and Beyond</li>
      </ul>
    </div>
    <div className="col-md-6">
      <img src={firstImg} alt="Investment Options" className="img-fluid" />
    </div>
  </div>

  {/* Section 2 */}
  <div className="row mb-5">
    <div className="col-md-6 d-flex flex-column justify-content-center order-md-2">
      <h2 className="landing-section-title">Personalized Investment Solutions</h2>
      <p className="landing-paragraph">
        Wealthworld doesn't just recommend investments; we tailor them to your needs. By asking the right questions, we understand your financial goals and suggest the best options for you. Plus, our Wealthworld Library is a treasure trove of knowledge, perfect for budding advisors or anyone looking to deepen their investment expertise.
      </p>
    </div>
    <div className="col-md-6 order-md-1">
      <img src={secondImg} alt="Personalized Solutions" className="img-fluid" />
    </div>
  </div>

  {/* Section 3 */}
  <div className="row">
    <div className="col-md-6 d-flex flex-column justify-content-center">
      <h2 className="landing-section-title">Your Investment, Our Priority</h2>
      <p className="landing-paragraph">
        At Wealthworld, we make investing easy and worry-free. We continuously monitor your portfolio, offering insights and strategies to optimize your returns. Whether you're a seasoned investor or just starting out, Wealthworld is here to guide, protect, and grow your wealth.
      </p>
    </div>
    <div className="col-md-6">
      <img src={thirdImg} alt="Investment Priority" className="img-fluid" />
    </div>
  </div>
</div>

      </div>
    </div>
  );

}

export default Home






